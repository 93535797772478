import { api } from './api';

export interface User {
  firstName: string,
  lastName: string,
  type: string,
  profilePic: string,
  status: string,
  referralCode: string
  id: string,
  permissions: any,
  roles?: any
}

export interface UserResponse {
  user: User
  token: string
}

export interface LoginRequest {
  email: string
  password: string,
  remember?: boolean | null
}

export interface ForgotPasswordRequest {
  email: string
}

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<UserResponse, LoginRequest>({
      query: (credentials) => ({
        url: `/admin/login`,
        method: 'POST',
        body: {
          email: credentials.email,
          password: credentials.password
        },
      }),
    }),
    forgotPassword: builder.mutation<any, ForgotPasswordRequest>({
      query: (credentials) => ({
        url: `/password/forgot-password`,
        method: 'POST',
        body: credentials,
      }),
    }),
    resetPassword: builder.mutation<any, any>({
      query: (credentials) => ({
        url: `/password/reset`,
        method: 'POST',
        body: credentials,
      }),
    }),
    getCompanyById: builder.query<any, any>({
      query: ({ id }) => {
        return `/companies/${id}`;
      },
    }),
    updateCompany: builder.mutation<any, any>({
      query({ id, body }) {
        return {
          url: `/companies/update/${id}`,
          method: 'POST',
          body,
        }
      }
    }),
  }),
})

export const { useUpdateCompanyMutation, useGetCompanyByIdQuery, useResetPasswordMutation, useLoginMutation, useForgotPasswordMutation } = authApi
