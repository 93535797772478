import { api } from './api';

export const reminderApi = api.injectEndpoints({
    endpoints: (builder) => ({
        listReminder: builder.query<any, any>({
            query: ({ page, perPage, q, ownerId, propertyId, contractId, dueDate, reminderDate, furnished, type }) => {
                let queryString = `page=${page}&perPage=${perPage}`;
                if (q) queryString += `&q=${q}`;
                if (ownerId) queryString += `&property_owner_id=${ownerId}`;
                if (propertyId) queryString += `&propertyId=${propertyId}`;
                if (contractId) queryString += `&tenancy_contract_id=${contractId}`;
                if (dueDate?.start && dueDate?.end) queryString += `&dueDateStart=${dueDate?.start}&dueDateEnd=${dueDate?.end}`;
                if (reminderDate?.start && reminderDate?.end) queryString += `&remainderDateStart=${reminderDate?.start}&remainderDateEnd=${reminderDate?.end}`;
                if (furnished) queryString += `&furnished=${furnished}`;
                if (type) queryString += `&type=${type}`;

                return `/listRemainders?${queryString}`;
            },
        }),
        createReminder: builder.mutation<any, any>({
            query({ body }) {
                return {
                    url: `/setRemainder`,
                    method: 'POST',
                    body,
                }
            }
        }),
        updateReminder: builder.mutation<any, any>({
            query({ id, body }) {
                return {
                    url: `/updateRemainder/${id}`,
                    method: 'POST',
                    body,
                }
            }
        }),
        deleteReminder: builder.mutation<any, any>({
            query({ id, body }) {
                return {
                    url: `/deleteRemainders/${id}`,
                    method: 'DELETE',
                    body,
                }
            }
        }),
        getReminderById: builder.query<any, any>({
            query: ({ id }) => {
                return `/showRemainder/${id}`;
            },
        }),
        listGeneratedCollectionReports: builder.mutation<any, any>({
            query: ({ body }) => {
                return {
                    url: `/collections`,
                    method: 'POST',
                    body,
                }
            },
        }),
    }),
})
export const { useListGeneratedCollectionReportsMutation, useListReminderQuery, useCreateReminderMutation, useUpdateReminderMutation, useGetReminderByIdQuery, useDeleteReminderMutation } = reminderApi
