import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

type ContractState = {
  page: number | undefined;
  perPage: number | undefined;
  searchStr: string;
};

const slice = createSlice({
  name: 'contract',
  initialState: { page: 1, perPage: 10, searchStr: '' } as ContractState,
  reducers: {
    setPginationData: (state, { payload: { page, perPage } }: PayloadAction<{ page: number | undefined; perPage: number | undefined }>) => {
      state.page = page;
      state.perPage = perPage;
    },
    setSearchStr: (state, { payload: { q } }: PayloadAction<{ q: string }>) => {
      state.searchStr = q;
      state.page = 1;
    },
  },
});

export const { setPginationData, setSearchStr } = slice.actions;

export default slice.reducer;

export const selectCurrentPage = (state: RootState) => state.contract.page;
export const selectPageSize = (state: RootState) => state.contract.perPage;
export const selectSearchStr = (state: RootState) => state.contract.searchStr;
