import { Modal } from 'antd';
import React from 'react';
import { useAuth } from '../../../hooks/useAuth';

interface ICModal {
  onCancel: any;
  footer: any;
  visible: any;
  title: any;
  width: string;
  children: any;
  className?: string;
  centered?: any;
  onOk?: any;
  maskClosable?: boolean;
  closable?: boolean;
}
const CModal = ({ onCancel, footer, visible, title, width, className, children, centered, onOk, maskClosable, closable }: ICModal) => {
  const { mode, color } = useAuth();
  return (
    <Modal closable={closable} maskClosable={maskClosable} onOk={onOk} centered={centered} className={`${mode} ${color} ${className}`} width={width} title={title} visible={visible} footer={footer} onCancel={onCancel}>
      {children}
    </Modal>
  );
};

export default CModal;
