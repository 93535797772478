import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import React, { useState } from 'react';

const SingleUpload = ({ url, setImageFile }: any) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const handleUploadRequest = async ({ status, file, onSuccess, filename, ...rest }: any) => {
    onSuccess('ok');
    setLoading(true);
    if (file) {
      setImageFile(file);
      getBase64(file as RcFile, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  return (
    <React.Fragment>
      <Upload customRequest={handleUploadRequest} name='avatar' listType='picture-card' className='avatar-uploader' showUploadList={false} beforeUpload={beforeUpload}>
        {imageUrl || url ? <img src={imageUrl ? imageUrl : url ? url : ''} alt='avatar' style={{ width: '100%' }} /> : uploadButton}
      </Upload>
    </React.Fragment>
  );
};

export default SingleUpload;
