import { Button, notification } from 'antd';
import React, { useEffect } from 'react';
import { useServiceWorker } from '../../useServiceWorker';

const PWARefresh = () => {
  const { waitingWorker, showReload, reloadPage } = useServiceWorker();

  const key = `open${Date.now()}`;
  const btn = (
    <Button
      type='link'
      size='small'
      onClick={() => {
        reloadPage();
        notification.close(key);
      }}>
      Apply
    </Button>
  );

  useEffect(() => {
    if (showReload && waitingWorker) {
      notification.warn({
        duration: 0,
        message: 'A new version of this website is available',
        btn,
        key,
        closeIcon: <div></div>,
      });
    }
  }, [waitingWorker, showReload, reloadPage]); //eslint-disable-line

  return <React.Fragment></React.Fragment>;
};

export default PWARefresh;
