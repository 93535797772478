import React, { Suspense } from 'react';
import PageOverlayLoader from './components/page-overlay-loader/page-overlay-loader.component';
import IRoute from './interface/IRoute';
const LazyErrorPage = React.lazy(() => import('./components/404/404.component'));
const LazySigninPage = React.lazy(() => import('./pages/siginin/signin.component'));
const LazyDashboardPage = React.lazy(() => import('./pages/dashboard/dashboard.component'));
const LazyStorePropertyPage = React.lazy(() => import('./pages/owner-property/store.property.component'));
const LazyStorePropertyOwnerPage = React.lazy(() => import('./pages/owner-property/store.property.owner.operations'));
const LazyTenantPage = React.lazy(() => import('./pages/tenant/tenant.component'));
const LazyTenantOperationPage = React.lazy(() => import('./pages/tenant/tenant.operations'));
const LazyPropertyPage = React.lazy(() => import('./pages/property/property.component'));
const LazyPropertyOperationPage = React.lazy(() => import('./pages/property/property.operations'));
const LazyContractPage = React.lazy(() => import('./pages/contract/contract.component'));
const LazyContractOperationPage = React.lazy(() => import('./pages/contract/contract.operations'));
const LazyRolesPage = React.lazy(() => import('./pages/admin/roles/roles.component'));
const LazyRolesOperationPage = React.lazy(() => import('./pages/admin/roles/roles.operations'));
const LazyUsersPage = React.lazy(() => import('./pages/admin/users/users.component'));
const LazyUserOperationPage = React.lazy(() => import('./pages/admin/users/users.operations'));
const LazyPermissionsPage = React.lazy(() => import('./pages/admin/permissions/permissions.component'));
const LazyCommissionPage = React.lazy(() => import('./pages/commission/commission.component'));
const LazyCommissionOperationPage = React.lazy(() => import('./pages/commission/commission.operations'));
const LazyReminderPage = React.lazy(() => import('./pages/reminder/reminder.component'));
const LazyReminderOperationPage = React.lazy(() => import('./pages/reminder/reminder.operations'));
const LazyChequePage = React.lazy(() => import('./pages/cheque/cheque.component'));
const LazyCashPage = React.lazy(() => import('./pages/cash/cash.component'));
const LazyAccountPage = React.lazy(() => import('./pages/accounts/account.component'));
const LazyReportPage = React.lazy(() => import('./pages/reports/report.component'));
const LazyInovicePage = React.lazy(() => import('./pages/invoice/invoice.component'));
const LazyContractRenewPage = React.lazy(() => import('./pages/contract/contract.renew.operation'));
const LazyPayOwnerPage = React.lazy(() => import('./pages/owner-property/pay.owner'));
const LazyBookedCustomerPage = React.lazy(() => import('./pages/customers/customer.compoent'));
const LazyResetPasswordPage = React.lazy(() => import('./pages/siginin/reset-password/reset.password'));
const LazyContractJourneyPage = React.lazy(() => import('./pages/contract/journey/journey'));
const LazySecurityDepositPage = React.lazy(() => import('./pages/contract/actions/securityDepositPage'));
const LazyReceiptPage = React.lazy(() => import('./pages/receipts/receipt.component'));
const LazyParkingPage = React.lazy(() => import('./pages/parking/parking.component'));
const LazyParkingOperationPage = React.lazy(() => import('./pages/parking/parking.operation'));
const LazyRegisterParkingPage = React.lazy(() => import('./pages/parking/register.parking'));
const LazyEditParkingOperationPage = React.lazy(() => import('./pages/parking/edit.contract'));
const LazyBudgetPage = React.lazy(() => import('./pages/budget/budget.component'));
const LazyBudgetOperationPage = React.lazy(() => import('./pages/budget/budget.operation'));

export const unProctedRoutes: IRoute[] = [
  {
    path: '/signin',
    exact: true,
    name: 'Signin Page',
    key: 'signin_page',
    component: () => (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazySigninPage />
      </Suspense>
    ),
  },
  {
    path: '/resetPassword/:email/:token',
    exact: true,
    name: 'Reset Password Page',
    key: 'reset_password_page',
    component: () => (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyResetPasswordPage />
      </Suspense>
    ),
  },
];

export const protectedRoutes: IRoute[] = [
  {
    path: '/error',
    exact: true,
    name: 'Error Page',
    key: 'error_page',
    component: () => (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyErrorPage />
      </Suspense>
    ),
  },
  {
    path: '/',
    exact: true,
    name: 'Dashboard Page',
    key: 'dashboard_page',
    component: () => (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyDashboardPage />
      </Suspense>
    ),
  },
  {
    path: '/roles',
    exact: true,
    name: 'Roles Page',
    key: 'roles_page',
    component: () => (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyRolesPage />
      </Suspense>
    ),
  },
  {
    path: '/rolesOperation',
    exact: true,
    name: 'Roles Operation Page',
    key: 'roles_operation_page',
    component: () => (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyRolesOperationPage />
      </Suspense>
    ),
  },
  {
    path: '/rolesOperation/:roleId',
    exact: true,
    name: 'Roles Edit Operation Page',
    key: 'roles_edit_operation_page',
    component: () => (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyRolesOperationPage />
      </Suspense>
    ),
  },
  {
    path: '/users',
    exact: true,
    name: 'Users Page',
    key: 'users_page',
    component: () => (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyUsersPage />
      </Suspense>
    ),
  },
  {
    path: '/usersOperation',
    exact: true,
    name: 'Users Operation Page',
    key: 'users_operation_page',
    component: () => (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyUserOperationPage />
      </Suspense>
    ),
  },
  {
    path: '/usersOperation/:userId',
    exact: true,
    name: 'Edit Users Operation Page',
    key: 'edit_users_operation_page',
    component: () => (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyUserOperationPage />
      </Suspense>
    ),
  },
  {
    path: '/permissions',
    exact: true,
    name: 'Permissions Page',
    key: 'permissions_page',
    component: () => (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyPermissionsPage />
      </Suspense>
    ),
  },
  {
    path: '/storeProperty',
    exact: true,
    name: 'Store Property Page',
    key: 'store_property_page',
    component: () => (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyStorePropertyPage />
      </Suspense>
    ),
  },
  {
    path: '/storePropertyOwner',
    exact: true,
    name: 'Create_Store_Property_Owner_Page',
    key: 'create_store_property_owner_page',
    component: () => (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyStorePropertyOwnerPage />
      </Suspense>
    ),
  },
  {
    path: '/storePropertyOwner/:storePropertyOwnerId',
    exact: true,
    name: 'Edit_Store_Property_Owner_Page',
    key: 'edit_store_property_owner_page',
    component: () => (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyStorePropertyOwnerPage />
      </Suspense>
    ),
  },
  {
    path: '/tenant',
    exact: true,
    name: 'Tenant Page',
    key: 'tenant_page',
    component: () => (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyTenantPage />
      </Suspense>
    ),
  },
  {
    path: '/tenantOperation',
    exact: true,
    name: 'Tenant Operation Page',
    key: 'tenant_operation_page',
    component: () => (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyTenantOperationPage />
      </Suspense>
    ),
  },
  {
    path: '/tenantOperation/:tenantId',
    exact: true,
    name: 'Edit Tenant Operation Page',
    key: 'edit_tenant_operation_page',
    component: () => (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyTenantOperationPage />
      </Suspense>
    ),
  },
  {
    path: '/property',
    exact: true,
    name: 'Property Page',
    key: 'property_page',
    component: () => (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyPropertyPage />
      </Suspense>
    ),
  },
  {
    path: '/propertyOperation',
    exact: true,
    name: 'Property Operation Page',
    key: 'property_operation_page',
    component: () => (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyPropertyOperationPage />
      </Suspense>
    ),
  },
  {
    path: '/propertyOperation/:propertyId',
    exact: true,
    name: 'Edit Property Operation Page',
    key: 'edit_property_operation_page',
    component: () => (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyPropertyOperationPage />
      </Suspense>
    ),
  },
  {
    path: '/contract',
    exact: true,
    name: 'Contract Page',
    key: 'contract_page',
    component: () => (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyContractPage />
      </Suspense>
    ),
  },
  {
    path: '/contractOperation',
    exact: true,
    name: 'Contract Operation Page',
    key: 'Contract_operation_page',
    component: () => (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyContractOperationPage />
      </Suspense>
    ),
  },
  {
    path: '/contractOperation/:contractId',
    exact: true,
    name: 'Edit Contract Operation Page',
    key: 'edit_contract_operation_page',
    component: () => (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyContractOperationPage />
      </Suspense>
    ),
  },
  {
    path: '/commission',
    exact: true,
    name: 'Commission Page',
    key: 'commission_page',
    component: () => (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyCommissionPage />
      </Suspense>
    ),
  },
  {
    path: '/commissionOperation',
    exact: true,
    name: 'Commission Operation Page',
    key: 'commission_operation_page',
    component: () => (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyCommissionOperationPage />
      </Suspense>
    ),
  },
  {
    path: '/commissionOperation/:commissionId',
    exact: true,
    name: 'Commission Operation Page',
    key: 'commission_operation_page_edit',
    component: () => (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyCommissionOperationPage />
      </Suspense>
    ),
  },
  {
    path: '/reminder',
    exact: true,
    name: 'Reminder Page',
    key: 'reminder_page',
    component: () => (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyReminderPage />
      </Suspense>
    ),
  },
  {
    path: '/reminderOperation',
    exact: true,
    name: 'Reminder Operation Page',
    key: 'reminder_operation_page',
    component: () => (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyReminderOperationPage />
      </Suspense>
    ),
  },
  {
    path: '/reminderOperation/:reminderId',
    exact: true,
    name: 'Reminder Operation Page',
    key: 'reminder_operation_page_edit',
    component: () => (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyReminderOperationPage />
      </Suspense>
    ),
  },
  {
    path: '/cheque/:contractId',
    exact: true,
    name: 'Cheque Page',
    key: 'cheque_page',
    component: () => (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyChequePage />
      </Suspense>
    ),
  },
  {
    path: '/cash/:contractId',
    exact: true,
    name: 'Cash Page',
    key: 'cash_page',
    component: () => (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyCashPage />
      </Suspense>
    ),
  },
  {
    path: '/accounts',
    exact: true,
    name: 'Account Page',
    key: 'account_page',
    component: () => (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyAccountPage />
      </Suspense>
    ),
  },
  {
    path: '/Reports',
    exact: true,
    name: 'Report Page',
    key: 'report_page',
    component: () => (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyReportPage />
      </Suspense>
    ),
  },
  {
    path: '/invoices',
    exact: true,
    name: 'Invoice Page',
    key: 'invoice_page',
    component: () => (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyInovicePage />
      </Suspense>
    ),
  },
  {
    path: '/contractRenew/:contractId',
    exact: true,
    name: 'Contract Renew Page',
    key: 'contract_renew_page',
    component: () => (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyContractRenewPage />
      </Suspense>
    ),
  },
  {
    path: '/pay-owner',
    exact: true,
    name: 'Pay Owner Page',
    key: 'pay_owner_page',
    component: () => (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyPayOwnerPage />
      </Suspense>
    ),
  },
  {
    path: '/view-customers',
    exact: true,
    name: 'View Customer Page',
    key: 'view_customer_page',
    component: () => (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyBookedCustomerPage />
      </Suspense>
    ),
  },
  {
    path: '/contract-journey/:contractId',
    exact: true,
    name: 'Contract Journey Page',
    key: 'contract_journey_page',
    component: () => (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyContractJourneyPage />
      </Suspense>
    ),
  },
  {
    path: '/receipts',
    exact: true,
    name: 'Receipts Page',
    key: 'receipt_page',
    component: () => (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyReceiptPage />
      </Suspense>
    ),
  },
  {
    path: '/security-deposit/:contractId',
    exact: true,
    name: 'Security Deposit Page',
    key: 'security_deposit_page',
    component: () => (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazySecurityDepositPage />
      </Suspense>
    ),
  },
  {
    path: '/parking',
    exact: true,
    name: 'Parking Page',
    key: 'parking_page',
    component: () => (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyParkingPage />
      </Suspense>
    ),
  },
  {
    path: '/parkingOperation',
    exact: true,
    name: 'Add Parking Page',
    key: 'parkingOperation',
    component: () => (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyParkingOperationPage />
      </Suspense>
    ),
  },
  {
    path: '/editParkingContract/:id',
    exact: true,
    name: 'Edit Parking Contract Page',
    key: 'editParkingContract',
    component: () => (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyEditParkingOperationPage />
      </Suspense>
    ),
  },
  {
    path: '/registerParking',
    exact: true,
    name: 'Register Parking Page',
    key: 'registerParking',
    component: () => (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyRegisterParkingPage />
      </Suspense>
    ),
  },
  {
    path: '/budget',
    exact: true,
    name: 'Budget Page',
    key: 'budgetPage',
    component: () => (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyBudgetPage />
      </Suspense>
    ),
  },
  {
    path: '/budgetOperation',
    exact: true,
    name: 'Budget Operation Page',
    key: 'budgetOperation',
    component: () => (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyBudgetOperationPage />
      </Suspense>
    ),
  },
  {
    path: '/budgetOperation/:id',
    exact: true,
    name: 'Budget Edit Operation Page',
    key: 'budgetEditOperation',
    component: () => (
      <Suspense fallback={<PageOverlayLoader loading={true} />}>
        <LazyBudgetOperationPage />
      </Suspense>
    ),
  },
];
