import { Button, Divider, Form, Input, InputNumber, notification, Skeleton } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import CModal from '../../components/antd-custom/modal/modal';
import SingleUpload from '../../components/single-file-uplaod/file-upload';
import { useAppDispatch } from '../../hooks/store';
import { useAuth } from '../../hooks/useAuth';
import { setVatPercentage } from '../../redux/authSlice';
import { useGetSignedUrlMutation } from '../../services/api';
import { useGetCompanyByIdQuery, useUpdateCompanyMutation } from '../../services/authApi';
import { handleUpload } from '../../utils';
import UseViewport from '../dashboard/dashboard-viewport';

const CustomizeCompanyDetails = ({ visible, setVisible }: any) => {
  const dispatch = useAppDispatch();
  let history: any = useHistory();
  const { width } = UseViewport();
  const { companyId } = useAuth();
  const [form] = Form.useForm();

  const [imageFile, setImageFile] = useState<any>(undefined);
  const [loadingBtn, setLoadingBtn] = useState(false);

  const [getSignedUrl] = useGetSignedUrlMutation();
  const [updateCompany] = useUpdateCompanyMutation();

  const { data: response, isLoading: companyLoading, isFetching: companyFetching, refetch } = useGetCompanyByIdQuery({ id: companyId ? companyId : '' });

  useEffect(() => {
    refetch();
  }, [refetch]);

  const companyData: any = useMemo(() => (response?.data ? response.data : []), [response]);
  const branchData: any = useMemo(() => (response?.data?.branches?.length > 0 ? response?.data?.branches[0] : []), [response]);

  const onFinish = async (values: any) => {
    try {
      if (values?.name !== '' && values?.name && companyId) {
        setLoadingBtn(true);
        let fileData: any = null;
        if (imageFile) fileData = await handleUpload(imageFile, getSignedUrl, 'company');

        let data: any = {
          name: values?.name ? values?.name : '',
          email: branchData?.email ? branchData?.email : '',
          branch_name: branchData?.branch_name ? branchData?.branch_name : '',
          phone: branchData?.phone ? branchData?.phone : '',
          address: branchData?.address ? branchData?.address : '',
          zipcode: branchData?.zipcode ? branchData?.zipcode : '',
          logo: fileData?.fileName ? fileData?.fileName : companyData?.logo ? companyData?.logo : '',
          vat_amount: Number(values?.vat) ? Number(values?.vat) : 0,
        };
        dispatch(setVatPercentage({ vat: Number(values?.vat) ? Number(values?.vat) : 5 }));
        await updateCompany({ id: companyId, body: data })
          .unwrap()
          .then((res: any) => {
            if (res?.success === true) {
              notification.success({
                message: 'Success',
                description: `Company Profile Updated Successfully`,
                placement: 'topRight',
              });
              refetch();
              setLoadingBtn(false);
              setVisible(false);
              window?.location?.reload();
            }
          });
      }
    } catch (error: any) {
      setLoadingBtn(false);
      setVisible(false);
      history?.push('/error');
      console.error(error);
    }
  };

  return (
    <CModal width={width <= 576 ? '100%' : '20%'} title='Company Profile' visible={visible} footer={null} onCancel={() => setVisible(!visible)}>
      <Skeleton loading={companyLoading || companyFetching}>
        <Form form={form} name='register' layout='vertical' onFinish={onFinish}>
          <div className='grid'>
            <div className='col-12 sm:col-12 md:col-12'>
              <Form.Item
                initialValue={companyData?.name ? companyData?.name : ''}
                name='name'
                label='Company Name'
                rules={[
                  {
                    pattern: new RegExp(/^[a-zA-Z0-9_.-\s]*$/),
                    message: 'Only alphabets and numbers allowed',
                  },
                ]}>
                <Input className='input-container' placeholder='Enter Name' />
              </Form.Item>
            </div>
            <div className='col-12 sm:col-12 md:col-12'>
              <Form.Item
                name='vat'
                label='Default Vat(%)'
                initialValue={Number(companyData?.vat_amount) ? Number(companyData?.vat_amount) : ''}
                rules={[
                  {
                    pattern: new RegExp(/^(0|[1-9][0-9]?|100)$/),
                    message: 'Vat should be between 1 to 100',
                  },
                ]}>
                <InputNumber className='mobile-input-container w-full' controls={false} stringMode />
              </Form.Item>
            </div>
            <Divider className='w-full m-0 p-0' />
            <div className='col-12 sm:col-12 md:col-12 flex justify-content-center align-items-center'>
              <Form.Item label='Logo'>
                <SingleUpload url={response?.url} setImageFile={setImageFile} />
              </Form.Item>
            </div>
            <Divider className='w-full m-0 p-0' />
            <div className='col-12 sm:col-12 md:col-12 mt-4'>
              <Form.Item className='w-full'>
                <Button loading={loadingBtn} htmlType='submit' className='dark-btn h-3rem w-full'>
                  {'SAVE'}
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </Skeleton>
    </CModal>
  );
};

export default CustomizeCompanyDetails;
