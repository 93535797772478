import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectCompany, selectCurrentUser, selectPermission, selectMode, selectColor, selectType, selectVat } from '../redux/authSlice'

export const useAuth = () => {
  const user: any = useSelector(selectCurrentUser);
  const permission: any = useSelector(selectPermission)
  const companyId: any = useSelector(selectCompany)
  const mode: any = useSelector(selectMode)
  const color: any = useSelector(selectColor)
  const type: any = useSelector(selectType)
  const vat: any = useSelector(selectVat)

  return useMemo(() => ({ vat, user, permission, companyId, mode, color, type }), [vat, user, permission, companyId, mode, color, type])
}

export interface IPreferences {
  screenName: String,
  referralCode: String,
  profilePic: string,
  bio: String
}