import { Layout, Menu, Typography } from 'antd';
import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { CloseOutlined } from '@ant-design/icons';
import './dashboard-layout.styles.less';
import lightLogo from '../../assets/rent/logo.png';
import UseViewport from './dashboard-viewport';
import CDrawer from '../../components/antd-custom/drawer/drawer';
import darkLogo from '../../assets/rent/logo-black.png';

const { Sider } = Layout;

const SideBar = ({ collapsed: showSidebar, activeMenu, setCollapsed: setShowSidebar }: any) => {
  const { permission, mode } = useAuth();
  const [collapsed, setCollapsed] = useState(false);
  const { width } = UseViewport();

  const NavItems = () => (
    <div>
      <Menu mode='inline' defaultSelectedKeys={[activeMenu]} selectedKeys={[activeMenu]}>
        <Menu.Item key='dashboard' icon={<i className='text-xl bx bx-category-alt'></i>}>
          <Link className='side-nav' to={`/`}>
            Dashboard
          </Link>
        </Menu.Item>
        {permission?.includes('roles') && (
          <Menu.Item key='roles' icon={<i className='text-xl bx bx-user-pin'></i>}>
            <Link className='side-nav' to={`/roles`}>
              Roles
            </Link>
          </Menu.Item>
        )}
        {permission?.includes('permissions') && (
          <Menu.Item key='permissions' icon={<i className='flex justify-content-center text-xl bx bx-street-view'></i>}>
            <Link className='side-nav' to={`/permissions`}>
              Permissions
            </Link>
          </Menu.Item>
        )}
        {permission?.includes('users') && (
          <Menu.Item key='users' icon={<i className='flex justify-content-center text-xl bx bx-user'></i>}>
            <Link className='side-nav' to={`/users`}>
              Users
            </Link>
          </Menu.Item>
        )}
        {permission?.includes('propertyOwners') && (
          <Menu.Item key='storeProperty' icon={<i className='text-xl bx bx-building-house'></i>}>
            <Link className='side-nav' to={`/storeProperty`}>
              Property Owners
            </Link>
          </Menu.Item>
        )}
        {permission?.includes('properties') && (
          <Menu.Item key='property' icon={<i className='text-xl bx bxs-city'></i>}>
            <Link className='side-nav' to={`/property`}>
              Property
            </Link>
          </Menu.Item>
        )}
        {permission?.includes('budgets') && (
          <Menu.Item key='budget' icon={<i className='text-xl bx bx-money-withdraw'></i>}>
            <Link className='side-nav' to={`/budget`}>
              Budget
            </Link>
          </Menu.Item>
        )}
        {permission?.includes('parkings') && (
          <Menu.Item key='parking' icon={<i className='text-xl bx bxs-parking'></i>}>
            <Link className='side-nav' to={`/parking`}>
              Parking
            </Link>
          </Menu.Item>
        )}
        {permission?.includes('tenants') && (
          <Menu.Item key='tenant' icon={<i className='text-xl bx bx-user-pin'></i>}>
            <Link className='side-nav' to={`/tenant`}>
              Tenant
            </Link>
          </Menu.Item>
        )}
        {permission?.includes('contracts') && (
          <Menu.Item key='contract' icon={<i className='text-xl bx bx-file'></i>}>
            <Link className='side-nav' to={`/contract`}>
              Contract
            </Link>
          </Menu.Item>
        )}
        {permission?.includes('commissions') && (
          <Menu.Item key='commission' icon={<i className='text-base fa-solid fa-percent text-medium'></i>}>
            <Link className='side-nav' to={`/commission`}>
              Commission
            </Link>
          </Menu.Item>
        )}
        {permission?.includes('reminders') && (
          <Menu.Item key='reminder' icon={<i className='text-xl bx bxs-bar-chart-alt-2'></i>}>
            <Link className='side-nav' to={`/reminder`}>
              Reminder
            </Link>
          </Menu.Item>
        )}
        {permission?.includes('accounts') && (
          <Menu.Item key='accounts' icon={<i className='fa-solid fa-address-book text-base'></i>}>
            <Link className='side-nav' to={`/accounts`}>
              Accounts
            </Link>
          </Menu.Item>
        )}
        {permission?.includes('reports') && (
          <Menu.Item key='reports' icon={<i className='fa-solid fa-receipt text-base'></i>}>
            <Link className='side-nav' to={`/reports`}>
              Reports
            </Link>
          </Menu.Item>
        )}
        {permission?.includes('invoices') && (
          <Menu.Item key='invoice' icon={<i className='text-base fa-solid fa-print'></i>}>
            <Link className='side-nav' to={`/invoices`}>
              Invoices
            </Link>
          </Menu.Item>
        )}
        {permission?.includes('receipts') && (
          <Menu.Item key='receipt' icon={<i className='text-xl bx bx-notepad'></i>}>
            <Link className='side-nav' to={`/receipts`}>
              Receipts
            </Link>
          </Menu.Item>
        )}
      </Menu>
    </div>
  );

  const isDark: boolean = useMemo(() => (mode === 'theme-mode-dark' ? true : false), [mode]);

  return (
    <React.Fragment>
      {width < 720 ? (
        <CDrawer
          closable={false}
          className='mobile-sidebar'
          title={
            <div className='flex justify-content-between align-items-center px-3'>
              <a href='/'>
                <img src={mode === 'theme-mode-light' ? lightLogo : darkLogo} alt='logo' style={{ width: 80, height: 60 }} />
              </a>
              <div onClick={() => setShowSidebar(!showSidebar)} style={{ backgroundColor: isDark ? 'var(--main-color)' : '#09263D', borderRadius: 5 }} className='flex justify-content-center align-items-center px-3 py-1 ml-1 cursor-pointer'>
                <Typography.Text className='text-sm text-white font-semibold'>
                  <CloseOutlined />
                </Typography.Text>
              </div>
            </div>
          }
          placement={'left'}
          onClose={() => setShowSidebar(!showSidebar)}
          visible={showSidebar}
          key={'left'}>
          <NavItems />
        </CDrawer>
      ) : (
        <Sider id='sidebar' className='sidebar shadow-1' collapsible collapsed={collapsed} onCollapse={(value: any) => setCollapsed(value)}>
          <NavItems />
        </Sider>
      )}
    </React.Fragment>
  );
};

export default SideBar;
