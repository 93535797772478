import moment from 'moment'
import { notification } from 'antd';
import axios from 'axios';

export const checkURL = (url: string) => {
    return (url?.match(/\.(jpeg|jpg|gif|png)$/) != null);
}

export const urlToFile = async (url: string, filename: string, mimeType: string) => {
    const res = await fetch(url);
    const buf = await res.arrayBuffer();
    return new File([buf], filename, { type: mimeType });
};

export const getBase64 = (img: any, callback: any) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};

export const beforeFileUpload = (file: any) => {
    const supportedFileType = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'application/pdf';
    if (!supportedFileType) {
        notification.error({
            message: 'Notification',
            description: 'You can only upload JPG/PNG/JPEG image and PDF files!',
        });
        return;
    }
    const fileSize = file.size / 1024 / 1024 < 5;
    if (!fileSize) {
        notification.error({
            message: 'Notification',
            description: 'File size must be smaller than 5MB!',
        });
        return;
    }
    return supportedFileType && fileSize;
};

export const calculateDueIn = (createdAt: string) => {
    const date1 = (moment());
    const date2 = (moment(createdAt));
    let dueTime = 'overdue';
    if (date1.diff(date2, 'minutes') < 60) {
        dueTime = date1.diff(date2, 'minutes') + ' mins'
    } else if (date1.diff(date2, 'hours') > 0 && date1.diff(date2, 'hours') < 48) {
        dueTime = 48 - date1.diff(date2, 'hours') + ' hrs';
    } else if (date1.diff(date2, 'days') < 2) {
        dueTime = date1.diff(date2, 'days') + ' days';
    }
    return dueTime;
}

export const formatDate = (date: string | undefined, fr: string) => {
    return moment(date).format(fr)
}

export const roundDecimalNumber = (number: number) => {
    const roundOfNumber = Math.round(number * 10);
    return roundOfNumber / 10;
}


export const handleUpload = async (imageFile: any, getSignedUrl: any, folderName: string) => {
    try {
        const myFile = new File([imageFile], `${moment().format('DD-MM-YYYYY-HH-mm-ss')}-${imageFile?.name?.trim()}`, {
            type: imageFile?.type,
        });
        const fileData = {
            fileName: myFile?.name,
            mimeType: myFile?.type,
            folderName: folderName,
        };
        let signedUrl: any = await getSignedUrl(fileData).unwrap();
        const options = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        if (signedUrl && signedUrl?.url) {
            axios.put(signedUrl.url, imageFile, options).catch((error: Error) => {
                console.error('error', error);
            });
            return fileData;
        }
    } catch (error: any) {
        console.error(error);
        return "";
    }
};