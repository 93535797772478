import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

type PropertyOwnerState = {
  page: number | undefined;
  perPage: number | undefined;
  searchStr: string;
  startDate: string;
  endDate: string;
  showDeleted: boolean;
  country: number | undefined;
};

const slice = createSlice({
  name: 'storePropertyOwner',
  initialState: { page: 1, perPage: 10, searchStr: '', startDate: '', endDate: '', showDeleted: false, country: undefined } as PropertyOwnerState,
  reducers: {
    setPginationData: (state, { payload: { page, perPage } }: PayloadAction<{ page: number | undefined; perPage: number | undefined }>) => {
      state.page = page;
      state.perPage = perPage;
    },
    setSearchStr: (state, { payload: { q } }: PayloadAction<{ q: string }>) => {
      state.searchStr = q;
      state.page = 1;
    },
    setDate: (state, { payload: { startDate, endDate } }: PayloadAction<{ startDate: string; endDate: string }>) => {
      state.startDate = startDate;
      state.endDate = endDate;
    },

    setCountryFilter: (state, { payload: { country } }: PayloadAction<{ country: number | undefined }>) => {
      state.country = country;
    },
  },
});

export const { setPginationData, setSearchStr, setDate, setCountryFilter } = slice.actions;

export default slice.reducer;

export const selectCurrentPage = (state: RootState) => state.storePropertyOwner.page;
export const selectPageSize = (state: RootState) => state.storePropertyOwner.perPage;
export const selectSearchStr = (state: RootState) => state.storePropertyOwner.searchStr;
export const selectStartDate = (state: RootState) => state.storePropertyOwner.startDate;
export const selectEndDate = (state: RootState) => state.storePropertyOwner.endDate;
export const selectCountry = (state: RootState) => state.storePropertyOwner.country;
