import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { User } from '../services/authApi';
import type { RootState } from './store';

type AuthState = {
  user: User | null;
  token: string | null;
  permission: any;
  companyId: any;
  mode?: string;
  color?: string;
  type?: string;
  vat?: number;
};

const getUserType = (user: any) => {
  const id: number = user?.roles?.length > 0 && user?.roles[0]?.id ? Number(user?.roles[0]?.id) : 0;
  if (id !== 0 && id === 3) return 'rentManager';
  else if (id !== 0 && id === 2) return 'admin';
  else if (id !== 0 && id === 4) return 'salesAgent';
  else return '';
};

const slice = createSlice({
  name: 'auth',
  initialState: { user: null, token: null, permission: [], companyId: '', mode: '', color: '', vat: 0 } as AuthState,
  reducers: {
    setCredentials: (state, { payload: { user, token, permisssions, company_id, vat_amount } }: PayloadAction<{ user: User; token: string; permisssions?: any; company_id: number; vat_amount: number }>) => {
      state.user = user;
      state.type = getUserType(user);
      state.companyId = company_id ? company_id : '';
      state.token = token;
      state.permission = permisssions;
      state.mode = 'theme-mode-light';
      state.color = 'theme-color-blue';
      state.vat = vat_amount ? vat_amount : 0;
    },
    logout: (state) => {
      state.permission = null;
      state.user = null;
      state.token = null;
      state.mode = '';
      state.color = '';
    },
    setMode: (state, { payload: { mode } }: PayloadAction<{ mode: string }>) => {
      state.mode = mode;
    },
    setColor: (state, { payload: { color } }: PayloadAction<{ color: string }>) => {
      state.color = color;
    },
    setVatPercentage: (state, { payload: { vat } }: PayloadAction<{ vat: number }>) => {
      state.vat = vat;
    },
  },
});

export const { setCredentials, logout, setMode, setColor, setVatPercentage } = slice.actions;

export default slice.reducer;

export const selectCurrentUser = (state: RootState) => state.auth.user;
export const selectPermission = (state: RootState) => state.auth.permission;
export const selectCompany = (state: RootState) => state.auth.companyId;
export const selectMode = (state: RootState) => state.auth.mode;
export const selectColor = (state: RootState) => state.auth.color;
export const selectType = (state: RootState) => state.auth.type;
export const selectVat = (state: RootState) => state.auth.vat;
