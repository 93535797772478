import { Button, DatePicker, Divider, Form, Input, Tooltip, Typography, notification as antNotification } from 'antd';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import CModal from '../../components/antd-custom/modal/modal';
import { useAuth } from '../../hooks/useAuth';
import { useUpdateReminderMutation } from '../../services/reminderApi';
import UseViewport from '../dashboard/dashboard-viewport';

const { Text } = Typography;
const { TextArea } = Input;

const CustomizeNotificationModal = ({ notification, visible, setVisible }: any) => {
  const [form] = Form.useForm();
  const { width } = UseViewport();
  const { mode } = useAuth();
  let history: any = useHistory();
  const [loadingBtn, setLoadingBtn] = useState(false);

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const DescriptionItem = ({ title, content }: { title: string; content: string }) => (
    <div className='flex flex-column text-overflow-ellipsis capitalize'>
      <Text className='text-sm mt-1' style={{ color: '#999' }}>
        {title}
      </Text>
      <Text className='font-bold text-base mt-1 custom-color'>
        <Tooltip title={content}>{content}</Tooltip>
      </Text>
    </div>
  );

  const [updateReminder] = useUpdateReminderMutation();

  const onFinish = async (values: any) => {
    if (notification?.id) {
      setLoadingBtn(true);
      let data: any = {
        remainder_title: notification?.remainder_title ? notification?.remainder_title : '',
        due_datetime: notification?.due_datetime ? notification?.due_datetime : '',
        type: notification?.type ? notification?.type : '',
        remainder: values?.reminderOn && moment(values?.reminderOn).format('YYYY-MM-DD HH:mm:ss'),
        remainder_notes: String(values.note),
      };
      await updateReminder({ id: notification.id, body: data })
        .unwrap()
        .then((res: any) => {
          if (res?.success === true)
            antNotification.success({
              message: 'Success',
              description: `Reminder Extended Successfully`,
              placement: 'topRight',
            });
          setLoadingBtn(false);
          setVisible(false);
        })
        .catch((error) => {
          history?.push('/error');
          console.error(error);
        })
        .finally(() => {
          setLoadingBtn(false);
          setVisible(false);
        });
    }
  };

  const isDark: boolean = useMemo(() => (mode === 'theme-mode-dark' ? true : false), [mode]);

  return (
    <React.Fragment>
      <CModal width={width <= 576 ? '100%' : '30%'} centered={true} title={<span className='text-base font-semibold'>Customize Notification</span>} visible={visible} onOk={handleOk} onCancel={handleCancel} footer={null}>
        <Form form={form} name='register' layout='vertical' onFinish={onFinish}>
          <div className='grid'>
            <div className='col-12 sm:col-6 md:col-6'>
              <DescriptionItem title='SlNo' content={notification?.SlNo ? notification?.SlNo : '-'} />
            </div>
            <div className='col-12 sm:col-6 md:col-6'>
              <DescriptionItem title='Type' content={notification?.type ? notification?.type : '-'} />
            </div>
            <Divider className='m-0' style={{ backgroundColor: isDark ? 'var(--txt-color)' : '' }} />
            <div className='col-12 sm:col-6 md:col-6'>
              <DescriptionItem title='Due Date' content={notification?.due_datetime !== null && moment(notification?.due_datetime).format('DD-MM-YYYY') ? moment(notification?.due_datetime).format('DD-MM-YYYY') : '-'} />
            </div>
            <div className='col-12 sm:col-6 md:col-6'>
              <DescriptionItem title='Remainder On' content={notification?.remainder !== null && moment(notification?.remainder).format('DD-MM-YYYY') ? moment(notification?.remainder).format('DD-MM-YYYY') : '-'} />
            </div>
            <Divider className='m-0' style={{ backgroundColor: isDark ? 'var(--txt-color)' : '' }} />
            <div className='col-12 sm:col-6 md:col-12'>
              <DescriptionItem title='Title' content={notification?.remainder_title ? notification?.remainder_title : '-'} />
            </div>
            <Divider className='m-0' style={{ backgroundColor: isDark ? 'var(--txt-color)' : '' }} />
            <div className='col-12 sm:col-6 md:col-12'>
              <DescriptionItem title='Note' content={notification?.remainder_notes ? notification?.remainder_notes : '-'} />
            </div>
            <Divider className='m-0 mb-3' style={{ backgroundColor: isDark ? 'var(--txt-color)' : '' }} />
            <div className='col-12 sm:col-12 md:col-12'>
              <Form.Item
                initialValue={moment(notification?.remainder) ? moment(notification?.remainder) : ''}
                name='reminderOn'
                label='Update Reminder Date'
                rules={[
                  {
                    required: true,
                    message: 'Please select reminder date',
                  },
                ]}>
                <DatePicker format={'DD-MM-YYYY'} className='date-picker-container w-full' />
              </Form.Item>
            </div>
            <div className='col-12 sm:col-12 md:col-12'>
              <Form.Item
                initialValue={notification?.remainder_notes ? notification?.remainder_notes : ''}
                name='note'
                label='Update Note'
                rules={[
                  {
                    required: true,
                    message: 'Please enter note',
                  },
                ]}>
                <TextArea rows={4} placeholder='PROVIDE A REASON FOR REFUND HERE' />
              </Form.Item>
            </div>
            <div className='col-12 sm:col-12 md:col-6'>
              <Form.Item>
                <Button onClick={() => setVisible(false)} className='light-btn h-3rem w-full'>
                  {'Cancel'}
                </Button>
              </Form.Item>
            </div>
            <div className='col-12 sm:col-12 md:col-6'>
              <Form.Item>
                <Button loading={loadingBtn} htmlType='submit' className='dark-btn h-3rem w-full'>
                  {'Extend'}
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </CModal>
    </React.Fragment>
  );
};

export default CustomizeNotificationModal;
