import React, { useEffect } from 'react';
import { useReactPWAInstall } from 'react-pwa-install';
import Logo from '../../assets/rent/logo.png';

const InstallPWA = ({ install, setInstall }: any) => {
  const { pwaInstall, isInstalled, supported } = useReactPWAInstall();

  useEffect(() => {
    if (install && supported() && !isInstalled()) {
      handleClick();
    }
  }, [install, isInstalled()]); //eslint-disable-line

  const handleClick = () => {
    pwaInstall({
      title: 'Install Sozo Rent',
      logo: Logo,
      features: (
        <ul>
          <li>Manage Owner</li>
          <li>Manage Tenant</li>
          <li>Manage Contract</li>
          <li>Manage Payments</li>
        </ul>
      ),
      description: 'This is a rent management app that does a lot of useful stuff.',
    })
      .then(() => {
        console.log('installed');
      })
      .catch(() => {
        console.error('catch');
      })
      .finally(() => setInstall(false));
  };

  return <React.Fragment></React.Fragment>;
};

export default InstallPWA;
