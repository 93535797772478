import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

type TenantState = {
  page: number | undefined;
  perPage: number | undefined;
  searchStr: string;
  startDate: string;
  endDate: string;
  showDeleted: boolean;
  country: number | undefined;
};

const slice = createSlice({
  name: 'tenant',
  initialState: { page: 1, perPage: 10, searchStr: '', country: undefined } as TenantState,
  reducers: {
    setPginationData: (state, { payload: { page, perPage } }: PayloadAction<{ page: number | undefined; perPage: number | undefined }>) => {
      state.page = page;
      state.perPage = perPage;
    },
    setSearchStr: (state, { payload: { q } }: PayloadAction<{ q: string }>) => {
      state.searchStr = q;
      state.page = 1;
    },
    setDate: (state, { payload: { startDate, endDate } }: PayloadAction<{ startDate: string; endDate: string }>) => {
      state.startDate = startDate;
      state.endDate = endDate;
    },
    setNationality: (state, { payload: { country } }: PayloadAction<{ country: number | undefined }>) => {
      state.country = country;
    },
  },
});

export const { setPginationData, setSearchStr, setDate, setNationality } = slice.actions;

export default slice.reducer;

export const selectCurrentPage = (state: RootState) => state.tenant.page;
export const selectPageSize = (state: RootState) => state.tenant.perPage;
export const selectSearchStr = (state: RootState) => state.tenant.searchStr;
export const selectStartDate = (state: RootState) => state.tenant.startDate;
export const selectEndDate = (state: RootState) => state.tenant.endDate;
export const selectNationality = (state: RootState) => state.tenant.country;
