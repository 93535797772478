import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import UnProtectedRoute from './components/UnProtectedRoute';
import { DashboardLayout } from './layouts/dashboard/dashboard-layout.component';
import { protectedRoutes, unProctedRoutes } from './routes';
import NotFound from './components/404/404.component';
import './App.less';
import PWARefresh from './components/PWA/update.PWA';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <>
      <PWARefresh />
      <ScrollToTop />
      <Switch>
        <Route path={unProctedRoutes.map((route) => route.path)}>
          {unProctedRoutes.map((route) => (
            <UnProtectedRoute key={route.key} path={route.path} exact={route.exact} name={route.name} component={route.component} />
          ))}
        </Route>
        <Route path={protectedRoutes.map((route) => route.path)}>
          <DashboardLayout>
            {protectedRoutes.map((route) => (
              <PrivateRoute key={route.key} path={route.path} exact={route.exact} name={route.name} component={route.component} />
            ))}
          </DashboardLayout>
        </Route>
        <Route path='*' component={NotFound} />
      </Switch>
    </>
  );
}

export default App;
