import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { api } from '../services/api'
import authReducer from './authSlice'
import storePropertyOwnerReducer from './storePropertyOwnerSlice'
import tenantReducer from './tenantSlice'
import propertyReducer from './propertySlice'
import contractReducer from './contractSlice'
import rolesReducer from './rolesSlice'
import reminderReducer from './reminderSlice'
import commissionReducer from './commissionSlice'
import accountsReducer from './accountsSlice'
import invoiceReducer from './invoiceSlice'
import customerReducer from './customerSlice'
import userReducer from './userSlice'
import parkingReducer from './parkingSlice'
import budgetReducer from './budgetSlice'

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth']
}

const reducers = combineReducers({
    [api.reducerPath]: api.reducer,
    auth: authReducer,
    storePropertyOwner: storePropertyOwnerReducer,
    tenant: tenantReducer,
    property: propertyReducer,
    contract: contractReducer,
    roles: rolesReducer,
    reminder: reminderReducer,
    commission: commissionReducer,
    audit: accountsReducer,
    invoice: invoiceReducer,
    customer: customerReducer,
    user: userReducer,
    parking: parkingReducer,
    budget: budgetReducer
})

export const middlewares = [
    api.middleware
]

export default persistReducer(persistConfig, reducers);