import { api } from './api';

export const contractApi = api.injectEndpoints({
    endpoints: (builder) => ({
        listContract: builder.query<any, any>({
            query: ({ page, perPage, q, tenantId, propertyOwnerId, propertyId, unit, fromDate, toDate, status, furnished, duration }) => {
                let queryString = `page=${page}&perPage=${perPage}`;
                if (q) queryString += `&q=${q}`;
                if (tenantId) queryString += `&tenantId=${tenantId}`;
                if (propertyId) queryString += `&propertyId=${propertyId}`;
                if (propertyOwnerId) queryString += `&propertyOwnerId=${propertyOwnerId}`;
                if (unit) queryString += `&propertyId=${unit}`;
                if (fromDate) queryString += `&from_contract_date=${fromDate}`;
                if (toDate) queryString += `&to_contract_date=${toDate}`;
                if (status) queryString += `&status=${status}`;
                if (furnished) queryString += `&furnished=${furnished}`;
                if (duration) queryString += `&duration=${duration}`;

                return `/tenancycontracts?${queryString}`;
            },
        }),
        createContract: builder.mutation<any, any>({
            query({ body }) {
                return {
                    url: `/tenancycontracts`,
                    method: 'POST',
                    body,
                }
            }
        }),
        updateContract: builder.mutation<any, any>({
            query({ id, body }) {
                return {
                    url: `/tenancycontracts/update/${id}`,
                    method: 'POST',
                    body,
                }
            }
        }),
        deleteContract: builder.mutation<any, any>({
            query({ id, body }) {
                return {
                    url: `/tenancycontracts/${id}`,
                    method: 'DELETE',
                    body,
                }
            }
        }),
        getContractById: builder.query<any, any>({
            query: ({ id }) => {
                return `/tenancycontracts/${id}`;
            },
        }),
        getNotification: builder.query<any, any>({
            query: () => {
                return `/rentRemainder`;
            },
        }),
        updateNotificationViewedById: builder.mutation<any, any>({
            query({ id, body }) {
                return {
                    url: `/isViewedUpdate/${id}`,
                    method: 'POST',
                    body,
                }
            }
        }),
        getContractDropDownList: builder.query<any, any>({
            query: ({ propertyId }) => {
                let queryString = ``;
                if (propertyId) queryString += `property_id=${propertyId}`;
                return `/tenantsProperty?${queryString}`;
            },
        }),
        cancelContract: builder.mutation<any, any>({
            query({ id, body }) {
                return {
                    url: `/cancelledContract/${id}`,
                    method: 'POST',
                    body,
                }
            }
        }),
        contractDropdown: builder.query<any, any>({
            query: () => {
                return `/contractFilterDropdowns`;
            },
        }),
        makePropertyVacant: builder.mutation<any, any>({
            query({ id, body }) {
                return {
                    url: `/makePropertyVacant/${id}`,
                    method: 'POST',
                    body,
                }
            }
        }),
        contractDocument: builder.mutation<any, any>({
            query({ id, body }) {
                return {
                    url: `/uploadContractsFiles/${id}`,
                    method: 'POST',
                    body,
                }
            }
        }),
        completeContract: builder.mutation<any, any>({
            query({ id, body }) {
                return {
                    url: `/inactiveContract/${id}`,
                    method: 'POST',
                    body,
                }
            }
        }),
        getContractJourneyById: builder.query<any, any>({
            query: ({ id }) => {
                return `/contractJourney/${id}`;
            },
        }),
        getContractSecurityDepositById: builder.query<any, any>({
            query: ({ id }) => {
                return `/getSecurityDepositDetails/${id}`;
            },
        }),
        saveAsDraftDamage: builder.mutation<any, any>({
            query({ body }) {
                return {
                    url: `/createDamages`,
                    method: 'POST',
                    body,
                }
            }
        }),
        securityMakePayment: builder.mutation<any, any>({
            query({ body, id }) {
                return {
                    url: `/makePayment/${id}`,
                    method: 'POST',
                    body,
                }
            }
        }),
        payRentFromDeposit: builder.mutation<any, any>({
            query({ body }) {
                return {
                    url: `/payrentfromdeposit`,
                    method: 'POST',
                    body,
                }
            }
        }),
        deletePayment: builder.mutation<any, any>({
            query({ id, body }) {
                return {
                    url: `/deletePayment/${id}`,
                    method: 'DELETE',
                    body,
                }
            }
        }),

    }),
})
export const { useDeletePaymentMutation, usePayRentFromDepositMutation, useSecurityMakePaymentMutation, useSaveAsDraftDamageMutation, useGetContractSecurityDepositByIdQuery, useGetContractJourneyByIdQuery, useCompleteContractMutation, useContractDocumentMutation, useMakePropertyVacantMutation, useContractDropdownQuery, useCancelContractMutation, useGetContractDropDownListQuery, useUpdateNotificationViewedByIdMutation, useGetNotificationQuery, useListContractQuery, useCreateContractMutation, useGetContractByIdQuery, useUpdateContractMutation, useDeleteContractMutation } = contractApi
