import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

type AccountState = {
  page: number | undefined;
  perPage: number | undefined;
  type: string;
};

const slice = createSlice({
  name: 'Audit',
  initialState: { page: 1, perPage: 10, type: 'income' } as AccountState,
  reducers: {
    setPginationData: (state, { payload: { page, perPage } }: PayloadAction<{ page: number | undefined; perPage: number | undefined }>) => {
      state.page = page;
      state.perPage = perPage;
    },
    setType: (state, { payload: { type } }: PayloadAction<{ type: string }>) => {
      state.type = type;
      state.page = 1;
    },
  },
});

export const { setPginationData, setType } = slice.actions;

export default slice.reducer;

export const selectCurrentPage = (state: RootState) => state.audit.page;
export const selectPageSize = (state: RootState) => state.audit.perPage;
export const selectType = (state: RootState) => state.audit.type;
